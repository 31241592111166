<template>
  <div
    class="ac-card-container"
    @click="
      () => {
        if (!spinning)
          $router.push({ name: 'ACAPDetail', params: { id: detail.id } })
      }
    "
  >
    <a-spin :spinning="spinning">
      <div class="header">
        <a class="title">{{ detail.monitor_address }}</a>
        <span class="more-btn">
          <a-dropdown>
            <a-icon type="more" style="cursor: pointer" />
            <a-menu slot="overlay">
              <a-menu-item>
                <sync-button @click="sync(detail.id)"></sync-button>
              </a-menu-item>
              <a-menu-item>
                <edit-button
                  v-permission="{action: 'base.network_device.update', effect: 'disabled'}"
                  @click="$refs.inputDrawer.show(detail.id)"
                ></edit-button>
              </a-menu-item>
              <a-menu-item>
                <delete-button v-permission="{action: 'base.network_device.delete', effect: 'disabled'}" @confirm="confirm"></delete-button>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
        <source-status-tag
          :status="detail.status"
          style="float: right; margin-top: 13px"
        ></source-status-tag>
      </div>

      <div class="main-container">
        <div class="left">
          <img :src="imgUrl" style="margin-bottom: 16px" height="25" />
          <div class="name">
            <a-tooltip :title="detail.name">
              {{ detail.name }}
            </a-tooltip>
          </div>
        </div>
        <div class="right">
          <div class="progress-title">
            <a-tooltip title="CPU使用率"> CPU使用率 </a-tooltip>
          </div>
          <a-progress :percent="cpuPercent" />
          <div class="progress-title" style="margin-top: 22px">
            <a-tooltip title="内存使用率"> 内存使用率 </a-tooltip>
          </div>
          <a-progress :percent="memoryPercent" />
        </div>
      </div>

      <div class="bottom-container">
        <a-descriptions :column="4" layout="vertical" :colon="false">
          <a-descriptions-item label="AP">
            <span class="cBlue">{{ apCount }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="SSID">
            <span>{{ ssid }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="2.4G终端">
            <span>{{ connection2 }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="5G终端">
            <span>{{ connection5 }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="电源">
            <a-tooltip v-if="!isNaN(parseFloat(psu)) && isFinite(psu)">
              <template slot="title">
                <div v-for="item in psuList" :key="item.name">
                  <span>{{ item.name }}：</span>
                  <span>{{ item.value ? item.value + ' W' : '-' }}</span>
                </div>
              </template>
              <span>{{ psu }} W</span>
            </a-tooltip>
            <a-tooltip v-else>
              <template slot="title">
                <div v-for="item in psuList" :key="item.name">
                  <span>{{ item.name }}：</span>
                  <span>{{
                    item.status ? $t(`source_status.${item.status}`) : '-'
                  }}</span>
                </div>
              </template>
              <span class="text" :style="{ color: getColors(psu) }">{{
                psu
              }}</span>
            </a-tooltip>
          </a-descriptions-item>

          <a-descriptions-item label="风扇">
            <a-tooltip v-if="!isNaN(parseFloat(fan)) && isFinite(fan)">
              <template slot="title">
                <div v-for="item in fanList" :key="item.name">
                  <span>{{ item.name }}：</span>
                  <span>{{ item.value ? item.value + 'rps' : '-' }}</span>
                </div>
              </template>
              <span>{{ fan }} rps</span>
            </a-tooltip>
            <a-tooltip v-else>
              <template slot="title">
                <div v-for="item in fanList" :key="item.name">
                  <span>{{ item.name }}：</span>
                  <span>{{
                    item.status ? $t(`source_status.${item.status}`) : '-'
                  }}</span>
                </div>
              </template>
              <span class="text" :style="{ color: getColors(fan) }">{{
                fan
              }}</span>
            </a-tooltip>
          </a-descriptions-item>

          <a-descriptions-item label="温度">
            <a-tooltip v-if="!isNaN(parseFloat(temp)) && isFinite(temp)">
              <template slot="title">
                <div v-for="item in tempList" :key="item.name">
                  <span>{{ item.name }}：</span>
                  <span>{{ item.value ? item.value + ' °C' : '-' }}</span>
                </div>
              </template>
              <span style="color: orange">{{ temp }} °C</span>
            </a-tooltip>
            <span v-else class="text" :style="{ color: getColors(temp) }">{{
              temp
            }}</span>
          </a-descriptions-item>
          <a-descriptions-item></a-descriptions-item>
        </a-descriptions>
      </div>

      <hardware-input-drawer
        ref="inputDrawer"
        source-type="network_device"
        device-type="access_controller"
        :isSimple="true"
        @ok="$emit('ok', ['edit', detail])"
      ></hardware-input-drawer>
    </a-spin>
  </div>
</template>

<script>
import {
  deleteNetworkDevice,
  getNetworkDeviceFanList,
  getNetworkDeviceMonitorItemList,
  getNetworkDevicePSUList,
  getNetworkDeviceTemperatureList,
  syncNetworkDevice
} from '@/api/network-device'
import SourceStatusTag from '@/components/tag/SourceStatusTag'
import DeleteButton from '@/components/button/DeleteButton'
import EditButton from '@/components/button/EditButton'
import { sourceStatuses } from '@/utils/const'
import SyncButton from '@/components/button/SyncButton'

export default {
  name: 'ACCard',
  components: {
    SourceStatusTag,
    DeleteButton,
    EditButton,
    SyncButton,
    HardwareInputDrawer: () => import('@/components/drawer/HardwareInputDrawer')
  },
  props: {
    detail: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      spinning: false,
      monitorKeys: [
        'system.cpu.util',
        'vm.memory.util',
        'connect.count[ap]',
        'system.ssid',
        'connect.count[2.4G]',
        'connect.count[5G]'
      ],
      cpuPercent: 0,
      memoryPercent: 0,
      connection2: 0,
      connection5: 0,
      ssid: 0,
      psu: '',
      psuList: [],
      temp: '',
      tempList: [],
      fan: '',
      fanList: [],
      apCount: 0
    }
  },
  computed: {
    imgUrl () {
      if (this.detail.vendor === 'ruijie') {
        return require('@/assets/images/ruijie.png')
      } else if (this.detail.vendor === 'h3c') {
        return require('@/assets/images/h3c.png')
      } else if (this.detail.vendor === 'huawei') {
        return require('@/assets/images/huawei.png')
      } else if (this.detail.vendor === 'sundray') {
        return require('@/assets/images/sundray.png')
      } else if (this.detail.vendor === 'cisco') {
        return require('@/assets/images/cisco.png')
      }
      return require('@/assets/images/noImg.png')
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.spinning = true

      const p = Promise.all(
        this.monitorKeys.map(
          key =>
            new Promise((resolve, reject) => {
              getNetworkDeviceMonitorItemList(this.detail.id, { key })
                .then(res => {
                  if (res.data.data.length) { resolve(res.data.data[0].value * 1 || 0) } else resolve(0)
                })
                .catch(err => reject(err))
            })
        )
      ).then(res => {
        this.cpuPercent = res[0]
        this.memoryPercent = res[1]
        this.apCount = res[2]
        this.ssid = res[3]
        this.connection2 = res[4]
        this.connection5 = res[5]
      })

      const p1 = getNetworkDeviceFanList(this.detail.id).then(res => {
        const data = res.data.data
        if (data.length) {
          Promise.all(data.map(item => new Promise((resolve, reject) => {
            getNetworkDeviceMonitorItemList(this.detail.id, { key: `sensor.fan.value[${item.name}]` }).then(res => {
              resolve(res.data.data[0])
            }).catch(err => reject(err))
          }))).then(res => {
            this.fan = this.getMaxValue(res) + ' ' + res[0].unit
            this.fanList = data.map((item, index) => {
              return { name: item.name, value: res[index].value, status: item.status }
            })
          })
        } else this.fan = '无数据'
      })
      const p2 = getNetworkDevicePSUList(this.detail.id).then(res => {
        const data = res.data.data
        if (data.length) {
          const hasStatus = this.hasStatus(data)
          this.psu = hasStatus ? this.getStatus(data) : this.getMaxValue(data)
          this.PSUList = data.map(item => {
            return { name: item.name, value: item.value, status: item.status }
          })
        } else this.psu = '无数据'
      })
      const p3 = getNetworkDeviceTemperatureList(this.detail.id).then(res => {
        const data = res.data.data
        if (data.length) {
          Promise.all(data.map(item => new Promise((resolve, reject) => {
            getNetworkDeviceMonitorItemList(this.detail.id, { key: `sensor.temp.value[${item.name}]` }).then(res => {
              resolve(res.data.data[0])
            }).catch(err => reject(err))
          }))).then(res => {
            this.temp = this.getMaxValue(res) + ' ' + res[0].unit
            this.tempList = data.map((item, index) => {
              return { name: item.name, value: res[index].value, status: item.status }
            })
          })
        } else this.temp = '无数据'
      })
      Promise.all([p, p1, p2, p3]).then(res => {
        this.spinning = false
      })
    },
    sync (id) {
      syncNetworkDevice({ id }).then(res => {
        this.$message.success(res.message)
      })
    },
    confirm () {
      deleteNetworkDevice(this.detail.id).then(res => {
        this.$message.success(res.message)
        this.$emit('ok', ['delete', this.detail])
      })
    },
    getStatus (data) {
      const bool = data.every(item => item.status === 'normal')
      return bool ? '正常' : '有异常'
    },
    getMaxValue (data) {
      return data.reduce((max, obj) => {
        return obj.value > max ? obj.value : max
      }, data[0].value)
    },
    hasStatus (data) {
      return data.some(
        item =>
          sourceStatuses.findIndex(status => status === item.status) !== -1
      )
    },
    getColors (text) {
      if (text === '正常') return '#096DD9'
      if (text === '有异常') return '#FF5B73'
      if (text === '无数据') return '#B5B5B5'
      return 'rgba(0,0,0.85)'
    }
  },
  wacth: {
    detail: {
      handle (v) {
        //
      },
      deep: true
    }
  }
}
</script>

<style lang="less">
.ac-card-container {
  height: 350px;
  overflow: hidden;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 13px;
  cursor: pointer;

  .header {
    height: 48px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 48px;
    box-sizing: border-box;

    .more-btn {
      float: right;
      font-size: 24px;
      color: rgba(0, 0, 0, 0.25);
      margin-right: -10px;
    }
  }

  .main-container {
    height: 145px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    .left {
      width: 49%;
      text-align: center;
      padding: 30px 20px 0 4px;
      overflow: hidden;

      .name {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
        line-height: 20px;
        margin-bottom: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .tag {
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0;
      }
    }
    .right {
      padding: 24px 0 0 0;
      width: 43%;
      .progress-title {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
        line-height: 20px;
        margin-bottom: -4px;
        margin-right: 2.5em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .ant-progress-outer {
        margin-right: 0;
        padding-right: 0;
      }
      .ant-progress-text {
        position: absolute;
        right: 8px;
        top: -16px;
        font-size: 14px;
        font-weight: 500;
        color: #096dd9;
        line-height: 20px;
      }
    }
  }

  .bottom-container {
    height: 140px;
    background: #f5f9fd;
    border-radius: 8px;
    overflow: hidden;
    padding: 4px 12px 12px;

    .ant-descriptions-row {
      & > td {
        padding-bottom: 1px;
      }
      .ant-descriptions-item {
        text-align: center;
        .ant-descriptions-item-label {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.5);
          line-height: 20px;
          margin-top: 12px;
          &::after {
            margin: 0;
            top: 0;
          }
        }
        .ant-descriptions-item-content {
          font-size: 18px;
          font-weight: 500;
          line-height: 25px;
          span {
            font-size: 18px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 25px;
          }
          span.cBlue {
            color: #096dd9;
          }
          span.text {
            font-size: 16px;
            font-weight: 500;
            color: #096dd9;
            line-height: 22px;
          }
          span.abnormal {
            color: #ff5b73;
          }
        }
      }
    }
  }
}
</style>
